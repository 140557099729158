import { useDispatch, useSelector } from "react-redux";
import { EventUtils, ObjectUtils } from "../../utils";
import { RootState } from "../../reducers";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProfileContent, getSummaryPersonal, setPageSetting } from "../../actions";
import { SummaryPersonalModal } from "../../interfaces/formValidation";
import { ButtonComponent, DescriptionComponent } from "../../components";
import _ from "underscore";

export const SummaryPersonal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screens = useSelector((state: RootState) => state.common.screens);
  const summaryPersonal = useSelector((state: RootState) => state.profile.summaryPersonal);
  const personalInfo = useSelector((state: RootState) => state.profile.personalInfo);
  const [sessionSummaryPersonal, setsessionSummaryPersonal] = useState<SummaryPersonalModal>({
    showWarningModal: false,
    actionLink: ""
  });

  const getProfileData = useCallback(async () => {
    dispatch(await getProfileContent(true));
  }, [dispatch]);

  useEffect(() => {
    //This gets app config data and save into redux store
    dispatch(getSummaryPersonal());
    getProfileData();
  }, []);

  const handleEditAction = (link = "") => {
    link = sessionSummaryPersonal?.actionLink ? sessionSummaryPersonal?.actionLink : link;
    const menuItem = _.find(screens, (item) => item?.link === link);
    setPageSetting(menuItem ? menuItem.name : "WELCOME", true);
    console.log("screens: ", screens);
    navigate(`/${link}`);
  };

  const handleWarningModal = (link = "") => {
    if (sessionSummaryPersonal?.showWarningModal) {
      setsessionSummaryPersonal({
        showWarningModal: true,
        actionLink: link
      });
    } else {
      handleEditAction(link);
    }
  };

  const handleClose = () => {
    setsessionSummaryPersonal({
      showWarningModal: false,
      actionLink: ""
    });
  };

  console.log(handleEditAction, handleClose, handleWarningModal);
  const pageTitle = summaryPersonal?.tabName;
  const smokerStatusText = summaryPersonal?.smokerStatusText;
  const isbtnEditPersonalVisible = summaryPersonal?.isbtnEditPersonalVisible;
  const actionLink = summaryPersonal?.actionlink;
  const profileInfo = personalInfo?.result || {};
  const birthDate = profileInfo?.dateOfBirth?.display;
  const { address, gender, languageText, homePhone, workPhone, personalEmail, workEmail } = profileInfo || "";
  const changeNote = personalInfo?.changeNote;
  const maritalStatusText = personalInfo?.maritalStatusTextText;
  const employeeNameText = personalInfo?.employeeNameText;
  const contents = personalInfo?.contentResource ? personalInfo?.contentResource.controlResource?.PersonalInfo : {};
  const lblBirthDate = ObjectUtils.extractLabelFromObject(contents, "lblBirthDate");
  const lblGender = ObjectUtils.extractLabelFromObject(contents, "lblGender");
  const lblLanguage = ObjectUtils.extractLabelFromObject(contents, "lblLanguage");
  const lblmaritalStatusText = ObjectUtils.extractLabelFromObject(contents, "lblmaritalStatusText");
  const lblAddress = ObjectUtils.extractLabelFromObject(contents, "lblEditAddress");
  const lblHomePhone = ObjectUtils.extractLabelFromObject(contents, "lblHomePhone");
  const lblWorkPhone = ObjectUtils.extractLabelFromObject(contents, "lblWorkPhone");
  const lblPersonalEmail = ObjectUtils.extractLabelFromObject(contents, "lblPersonalEmail");
  const lblWorkEmail = ObjectUtils.extractLabelFromObject(contents, "lblWorkEmail");
  const lblEmailHeader = ObjectUtils.extractLabelFromObject(contents, "pnlEmailGroupingText");
  const lblPhoneHeader = ObjectUtils.extractLabelFromObject(contents, "pnlPhoneGroupingText");
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const editIcon = ObjectUtils.extractLabelFromObject(footerContent, "EditIcon");
  const backButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnBack");
  document.title = pageTitle;

  const btnBack = [
    {
      display: backButtonText?.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: () => navigate("/Summary")
    }
  ];

  return (
    <div className="summary summarypersonal clearfix">
      <div className={`d-flex titlewithicon mb-md-3`}>
        <ButtonComponent btndata={btnBack} />
      </div>
      <div className="ml-description">
        <p className="mb-4" dangerouslySetInnerHTML={{ __html: changeNote }} />
      </div>
      <Card>
        <Card.Header>
          <DescriptionComponent headingclassname="float-left" level="2" heading={employeeNameText} />
          {isbtnEditPersonalVisible && (
            <span
              className="float-right icon-action"
              tabIndex={0}
              role="button"
              aria-label={editIcon.textToRead + " " + employeeNameText}
              onClick={() => {
                handleWarningModal(actionLink);
              }}
              onKeyDown={(e) => EventUtils.keyPressed(e) && handleWarningModal(actionLink)}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </span>
          )}
        </Card.Header>
        <Card.Body className="personal-info-holder">
          <div className="row mb-2">
            <div className="col">
              <span>{lblBirthDate?.display}</span> {birthDate}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <span>{lblGender?.display}</span> {gender}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <span>{lblmaritalStatusText?.display}</span> {maritalStatusText}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <span>{lblAddress?.display}</span> {address?.address1} {address?.address2} {address?.city} {address?.provinceCode} {address?.postalCode} {address?.countryCode}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span>{lblLanguage?.display}</span> {languageText}
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <span dangerouslySetInnerHTML={{ __html: smokerStatusText }} />
        </Card.Footer>
      </Card>
      <Card>
        <Card.Header>
          <DescriptionComponent headingclassname="float-left" level="2" heading={lblPhoneHeader?.display} />
          {isbtnEditPersonalVisible && (
            <span
              className="float-right icon-action"
              tabIndex={0}
              role="button"
              aria-label={editIcon.textToRead + " " + lblPhoneHeader.textToRead}
              onClick={() => {
                handleWarningModal(actionLink);
              }}
              onKeyDown={(e) => EventUtils.keyPressed(e) && handleWarningModal(actionLink)}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </span>
          )}
        </Card.Header>
        <Card.Body className="personal-info-holder">
          <div className="row mb-2">
            <div className="col">
              <span>{lblWorkPhone?.display}</span> {workPhone?.areaCode} {workPhone?.phoneNumber} {workPhone?.extension}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span>{lblHomePhone?.display}</span> {homePhone?.areaCode} {homePhone?.phoneNumber}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <DescriptionComponent headingclassname="float-left" level="2" heading={lblEmailHeader?.display} />
          {isbtnEditPersonalVisible && (
            <span
              className="float-right icon-action"
              tabIndex={0}
              role="button"
              aria-label={editIcon?.textToRead + " " + lblEmailHeader?.textToRead}
              onClick={() => {
                handleWarningModal(actionLink);
              }}
              onKeyDown={(e) => EventUtils.keyPressed(e) && handleWarningModal(actionLink)}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </span>
          )}
        </Card.Header>
        <Card.Body className="personal-info-holder">
          <div className="row mb-2">
            <div className="col">
              <span>{lblWorkEmail?.display}</span> {workEmail}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span>{lblPersonalEmail?.display}</span> {personalEmail}
            </div>
          </div>
        </Card.Body>
      </Card>
      {/* <WarningMessageModal showWarningModal={this.state.showWarningModal} clickAction={this.handleEditAction} handleClose={this.handleClose} /> */}
    </div>
  );
};

export default SummaryPersonal;
