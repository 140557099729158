import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileContent, showSuccess, updatePersonalInfo } from "../../actions";
import { AlertComponent, CardComponent } from "../../components";
import { emailErrorMessage, infoErrorMessage, phoneErrorMessage } from "../../interfaces/formValidation";
import { RootState } from "../../reducers";
import { ObjectUtils, USER_TOKEN } from "../../utils";

export const ReviewPersonalInfo = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [infoErrorMessage, setinfoErrorMessage] = useState<infoErrorMessage>({
    firstNameError: "",
    lastNameError: "",
    initialError: "",
    address1Error: "",
    address2Error: "",
    cityError: "",
    postalCodeError: "",
    isValid: true
  });
  const [phoneErrorMessage, setphoneErrorMessage] = useState<phoneErrorMessage>({
    workAreaCodeError: "",
    workPhoneNumberError: "",
    workextensionError: "",
    homeAreaCodeError: "",
    homePhoneNumberError: "",
    isValid: true
  });

  const [emailErrorMessage, setemailErrorMessage] = useState<emailErrorMessage>({
    workemailError: "",
    personalemailError: ""
  });

  const [emailMode, setemailMode] = useState<string>("view");
  const [infoMode, setinfoMode] = useState<string>("view");
  const [phoneMode, setphoneMode] = useState<string>("view");
  const [errorList, setErrorList] = useState<string[]>([]);

  const [smokerDeclarationChanged, setsmokerDeclarationChanged] = useState<boolean>(false);
  const { contentFooter } = useSelector((state: RootState) => state.common);
  const profileData = useSelector((state: RootState) => state.profile.personalInfo);
  const [sessioncurrentUser, setsessioncurrentUser] = useState<any>([]);
  const local = USER_TOKEN.get()?.lang;
  // const resetSessioncurrentUser = useRef(true);

  const contents1 = profileData?.contentResource?.contents || [];
  const contents2 = profileData?.contentResource?.controlResource?.PersonalInfo || [];
  const contents: any = [...contents1, ...contents2];
  const { isAddressEditable, isNameEditable, isMaritalStatusUsed, isHomeEmailEditable, isWorkEmailEditable, isHomePhoneEditable, isWorkPhoneEditable, changeNote, showWorkPhone } =
    profileData || {};
  const showInfoEditable = isAddressEditable || isNameEditable || isMaritalStatusUsed;
  const showEmailEditable = isHomeEmailEditable || isWorkEmailEditable;
  const showPhoneEditable = isHomePhoneEditable || isWorkPhoneEditable;
  const lblBirthDate = ObjectUtils.extractLabelFromObject(contents, "lblBirthDate");
  const lblGender = ObjectUtils.extractLabelFromObject(contents, "lblGender");
  const lblMaritalStatus = ObjectUtils.extractLabelFromObject(contents, "lblMaritalStatus");
  const lblLanguage = ObjectUtils.extractLabelFromObject(contents, "lblLanguage");
  // const regFirstNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regFirstNameErrorMessage");

  const pnlPhoneGroupingText = ObjectUtils.extractLabelFromObject(contents, "pnlPhoneGroupingText");
  const pnlEmailGroupingText = ObjectUtils.extractLabelFromObject(contents, "pnlEmailGroupingText");

  const footerContent = contentFooter?.contentResource?.contents || {};
  const btnCancel = ObjectUtils.extractLabelFromObject(footerContent, "btnCancel");
  const btnSave = ObjectUtils.extractLabelFromObject(footerContent, "btnSave");
  const editIcon = ObjectUtils.extractLabelFromObject(footerContent, "EditIcon");
  const lblWorkEmail = ObjectUtils.extractLabelFromObject(contents, "lblWorkEmail");
  const lblPersonalEmail = ObjectUtils.extractLabelFromObject(contents, "lblPersonalEmail");
  const regAddress1errormessage = ObjectUtils.extractLabelFromObject(contents, "regAddress1ErrorMessage").display;
  const regAddress2errormessage = ObjectUtils.extractLabelFromObject(contents, "regAddress2ErrorMessage").display;
  const regCityerrormessage = ObjectUtils.extractLabelFromObject(contents, "regCityErrorMessage").display;
  const regPostalCodeCanadaErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regPostalCodeCanadaErrorMessage").display;
  const regPostalCodeUSErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regPostalCodeUSErrorMessage").display;
  const lblEditHomePhone = ObjectUtils.extractLabelFromObject(contents, "lblEditHomePhone");
  const lblEditWorkPhone = ObjectUtils.extractLabelFromObject(contents, "lblEditWorkPhone");
  const lblEditWorkEmail = ObjectUtils.extractLabelFromObject(contents, "lblEditWorkEmail");
  const lblEditPersonalEmail = ObjectUtils.extractLabelFromObject(contents, "lblEditPersonalEmail");
  const regHomePhoneErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regHomePhoneErrorMessage").display;
  const regWorkPhoneErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regWorkPhoneErrorMessage").display;
  // const regWorkPhoneAreaErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regWorkPhoneAreaErrorMessage").display;
  // const regHomePhoneAreaErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regHomePhoneAreaErrorMessage").display;
  const regExtensionErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regExtensionErrorMessage").display;
  const regWorkEmailErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regWorkEmailErrorMessage").display;
  const regPersonalEmailErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regPersonalEmailErrorMessage").display;
  const lblProfileCountryCodePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileCountryCodePlaceHolder");
  const lblProfileProvinceCodePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileProvinceCodePlaceHolder");
  const regLastNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regLastNameErrorMessage").display;
  const regFirstNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regFirstNameErrorMessage").display;
  const regMiddleInitialErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regMiddleInitialErrorMessage").display;
  const lblPreferredEmail = ObjectUtils.extractLabelFromObject(contents, "lblPreferredEmail");
  const lblEditAddress = ObjectUtils.extractLabelFromObject(contents, "lblEditAddress");
  const lblProfileAddress1PlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileAddress1PlaceHolder");
  const lblProfileAddress2PalceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileAddress2PalceHolder");
  const lblProfileCityPlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileCityPlaceHolder");
  const lblProfilePostalCodePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfilePostalCodePlaceHolder");
  const lblExtension = ObjectUtils.extractLabelFromObject(contents, "lblExtension");
  const lblEnterTenDigits = ObjectUtils.extractLabelFromObject(contents, "lblEnterTenDigits");
  const lblProfileHomePhonePhoneNumberPlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileHomePhonePhoneNumberPlaceHolder");
  const lblProfileInitialPlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileInitialPlaceHolder");
  const lblEditInitial = ObjectUtils.extractLabelFromObject(contents, "lblEditInitial");
  const lblEditLastName = ObjectUtils.extractLabelFromObject(contents, "lblEditLastName");
  const lblEditFirstName = ObjectUtils.extractLabelFromObject(contents, "lblEditFirstName");
  const lblProfileFirstNamePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileFirstNamePlaceHolder");
  const lblProfileLastNamePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileLastNamePlaceHolder");
  const lblSmoker = ObjectUtils.extractLabelFromObject(contents, "lblSmoker").display;
  const lblNonSmoker = ObjectUtils.extractLabelFromObject(contents, "lblNonSmoker").display;
  const smokerStatusTitle = ObjectUtils.extractLabelFromObject(contents, "pnlSmokerGroupingText");
  const nonSmokerMessage = ObjectUtils.extractLabelFromObject(contents, "lblIsNonSmoker");
  const smokerMessage = ObjectUtils.extractLabelFromObject(contents, "lblIsSmoker");

  const setDefaultSessioncurrentUser = useCallback((result: any) => {
    setsessioncurrentUser(() => {
      const _session = {
        ...result,
        homePhone: {
          ...result.homePhone,
          formattedPhoneNumber: formatPhoneNumber((result?.homePhone?.areaCode ?? "") + result?.homePhone?.phoneNumber ?? "")
        },
        workPhone: {
          ...result.workPhone,
          formattedPhoneNumber: formatPhoneNumber((result?.workPhone?.areaCode ?? "") + result?.workPhone?.phoneNumber ?? "")
        }
      };
      return _session;
    });
  }, []);

  const resetErrors = () => {
    setErrorList([]);
    setinfoErrorMessage({
      firstNameError: "",
      lastNameError: "",
      initialError: "",
      address1Error: "",
      address2Error: "",
      cityError: "",
      postalCodeError: "",
      isValid: true
    });
    setphoneErrorMessage({
      workAreaCodeError: "",
      workPhoneNumberError: "",
      workextensionError: "",
      homeAreaCodeError: "",
      homePhoneNumberError: "",
      isValid: true
    });
    setemailErrorMessage({
      workemailError: "",
      personalemailError: ""
    });
  };

  useEffect(() => {
    if (profileData) {
      setDefaultSessioncurrentUser(profileData?.result);
    }
  }, [profileData, setDefaultSessioncurrentUser]);

  const getProfileData = useCallback(
    async (isReview: boolean) => {
      dispatch(await getProfileContent(isReview));
    },
    [dispatch]
  );

  useEffect(() => {
    setErrorList([]);
    // if (!profileData) {
    // console.log("profileData: ", profileData);
    if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
      getProfileData(false);
    } else {
      getProfileData(true);
    }
    // }
  }, [getProfileData]);

  useEffect(() => {
    resetErrors();
  }, [local]);

  const changePreferredEmailStatus = async (value: any) => {
    setemailErrorMessage({ workemailError: "", personalemailError: "" });

    const newvalue: any = {};
    newvalue.selectedEmail = value?.selectedpersonalEmail ? value?.selectedpersonalEmail : value?.selectedWorkEmail;

    const currentUser: any = { ...sessioncurrentUser, ...newvalue };
    setsessioncurrentUser(currentUser);

    // dispatch(await updatePersonalInfo(currentUser));
  };

  const onInputChange = (value: any) => {
    if (infoMode == "edit") isValidInfo(value);
    setsessioncurrentUser({
      ...sessioncurrentUser,
      ...value
    });
  };

  const onInputChangeAddress = (value: any) => {
    isValidInfo(value);
    setsessioncurrentUser({
      ...sessioncurrentUser,
      address: {
        ...sessioncurrentUser.address,
        ...value
      }
    });
  };

  const changeSmokerStatus = async (callback: any) => {
    const value: any = Object.values(callback)[0];
    setsmokerDeclarationChanged(true);
    const currentUser: any = { ...sessioncurrentUser, smokerStatus: parseInt(value) };
    setsessioncurrentUser(currentUser);
    dispatch(await updatePersonalInfo(currentUser));
  };

  const isValidEmailInfo = () => {
    let isValid = true;
    setemailErrorMessage({ workemailError: "", personalemailError: "" });
    if (ObjectUtils.isTest(sessioncurrentUser?.workEmail, profileData?.validationWorkEmail)) {
      setemailErrorMessage({
        ...emailErrorMessage,
        workemailError: regWorkEmailErrorMessage
      });
      isValid = false;
    }
    if (ObjectUtils.isTest(sessioncurrentUser?.personalEmail, profileData?.validationPersonalEmail)) {
      setemailErrorMessage({
        ...emailErrorMessage,
        personalemailError: regPersonalEmailErrorMessage
      });
      isValid = false;
    }
    return isValid;
  };

  const isValidInfo = (value: any) => {
    const { validationforFirstName, validationforLastName, validationforInitial, validationForAddress, validationPostalCodeUS, validationPostalCodeCanada } = profileData || {};
    const { countryCode } = sessioncurrentUser?.address || {};
    const validationPostalCode = countryCode == "CA" ? validationPostalCodeCanada : countryCode == "US" ? validationPostalCodeUS : "";
    const regPostalCodeErrorMessage = countryCode == "CA" ? regPostalCodeCanadaErrorMessage : countryCode == "US" ? regPostalCodeUSErrorMessage : "";

    if (value.firstName !== undefined) {
      if (ObjectUtils.isTest(value?.firstName, validationforFirstName)) {
        infoErrorMessage.firstNameError = regFirstNameErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.firstNameError = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.lastName !== undefined) {
      if (ObjectUtils.isTest(value?.lastName, validationforLastName)) {
        infoErrorMessage.lastNameError = regLastNameErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.lastNameError = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.initial !== undefined) {
      if (ObjectUtils.isTest(value?.initial, validationforInitial)) {
        infoErrorMessage.initialError = regMiddleInitialErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.initialError = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.address1 !== undefined) {
      if (ObjectUtils.isTest(value?.address1, validationForAddress)) {
        infoErrorMessage.address1Error = regAddress1errormessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.address1Error = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.address2 !== undefined) {
      if (ObjectUtils.isTest(value?.address2, validationForAddress)) {
        infoErrorMessage.address2Error = regAddress2errormessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.address2Error = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.city !== undefined) {
      if (ObjectUtils.isTest(value?.city, validationForAddress)) {
        infoErrorMessage.cityError = regCityerrormessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.cityError = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.postalCode !== undefined) {
      if (ObjectUtils.isTest(value?.postalCode, validationPostalCode)) {
        infoErrorMessage.postalCodeError = regPostalCodeErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.postalCodeError = "";
        infoErrorMessage.isValid = true;
      }
    }
    setinfoErrorMessage(infoErrorMessage);
  };

  const message = {
    homePhoneNumberError: regHomePhoneErrorMessage,
    workPhoneNumberError: regWorkPhoneErrorMessage,
    workextensionError: regExtensionErrorMessage
  };

  const handleSaveClick = (cardName: string) => {
    if (cardName === "email") {
      const isValid = isValidEmailInfo();
      if (isValid) handleSubmit(cardName);
    } else handleSubmit(cardName);
  };

  const handleSubmit = async (cardName: string) => {
    dispatch(
      await updatePersonalInfo(
        sessioncurrentUser,
        (response) => {
          setErrorList([]);
          if (cardName === "info") reverseInfoMode();
          else if (cardName === "phone") reversePhoneMode();
          else if (cardName === "email") reverseEmailMode();
          response?.result?.Message && showSuccess(response?.result?.Message);
        },
        (errorMessage) => {
          setErrorList(errorMessage);
        }
      )
    );
  };

  const cancel = (value: string) => {
    if (value == "info") setinfoMode(infoMode === "edit" ? "view" : "edit");
    else if (value == "phone") setphoneMode(phoneMode === "edit" ? "view" : "edit");
    else if (value == "email") setemailMode(emailMode === "edit" ? "view" : "edit");

    setDefaultSessioncurrentUser({ ...profileData?.result });

    resetErrors();
  };

  const reverseEmailMode = () => {
    if (infoMode == "edit") cancel("info");
    if (phoneMode == "edit") cancel("phone");
    setemailMode(emailMode == "edit" ? "view" : "edit");
    setemailErrorMessage({
      workemailError: "",
      personalemailError: ""
    });
    getProfileData(false);
  };

  const reversePhoneMode = () => {
    if (infoMode == "edit") cancel("info");
    if (emailMode == "edit") cancel("email");
    setphoneMode(phoneMode == "edit" ? "view" : "edit");
    setphoneErrorMessage({
      workAreaCodeError: "",
      workPhoneNumberError: "",
      workextensionError: "",
      homeAreaCodeError: "",
      homePhoneNumberError: "",
      isValid: true
    });
    getProfileData(false);
  };

  const reverseInfoMode = () => {
    if (phoneMode == "edit") cancel("phone");
    if (emailMode == "edit") cancel("email");
    setinfoMode(infoMode === "edit" ? "view" : "edit");
    getProfileData(false);
  };

  const validatePhoneNumber = (phoneNumber: string, errorField?: string) => {
    const tenDigitReg = /^\D*(\d\D*){10}$/;
    const isValid = phoneNumber.trim().length === 0 || phoneNumber?.match(tenDigitReg);
    if (errorField) {
      setphoneErrorMessage({ ...phoneErrorMessage, isValid: !!isValid, [errorField]: isValid ? "" : message[errorField] });
    }
    if (!isValid) {
      return false;
    }
    return true;
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    const _digitsOnly = phoneNumber?.replace(/[^0-9]/g, "");
    if (!_digitsOnly) {
      return "";
    }
    return `(${_digitsOnly.slice(0, 3)}) ${_digitsOnly.slice(3, 6)}-${_digitsOnly.slice(6)}`;
  };

  const formatValidPhoneNumber = (phoneNumber: string, errorField?: string) => {
    if (validatePhoneNumber(phoneNumber, errorField)) {
      return formatPhoneNumber(phoneNumber);
    }

    return phoneNumber;
  };

  const changePhoneNumber = (field: string, errorField: string, phoneNumber: string, format = false) => {
    validatePhoneNumber(phoneNumber, errorField);
    const _digitsOnly = phoneNumber?.replace(/[^0-9]/g, "");

    if (format && phoneNumber.trim().length > 0) {
      phoneNumber = formatValidPhoneNumber(phoneNumber, errorField);
    }

    setsessioncurrentUser({
      ...sessioncurrentUser,
      [field]: {
        ...sessioncurrentUser[field],
        formattedPhoneNumber: phoneNumber,
        areaCode: _digitsOnly?.slice(0, 3),
        phoneNumber: _digitsOnly?.slice(3)
      }
    });
  };

  const handleExtensionChange = (value: Record<string, string>) => {
    const { validationWorkPhoneExtension } = profileData || {};
    if (ObjectUtils.isTest(value?.extension, validationWorkPhoneExtension)) {
      phoneErrorMessage.workextensionError = regExtensionErrorMessage;
      phoneErrorMessage.isValid = false;
    } else {
      phoneErrorMessage.workextensionError = "";
      phoneErrorMessage.isValid = true;
    }
    setsessioncurrentUser({
      ...sessioncurrentUser,
      workPhone: {
        ...sessioncurrentUser.workPhone,
        ...value
      }
    });
  };

  const onlyNumber = (e: any) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  let smokerStatusMessage: any = "";
  if (["0", 0].indexOf(sessioncurrentUser?.smokerStatus) > -1) smokerStatusMessage = nonSmokerMessage;
  if (["1", 1].indexOf(sessioncurrentUser?.smokerStatus) > -1) smokerStatusMessage = smokerMessage;

  const address = sessioncurrentUser?.address || {};

  const returnAddressText = () => {
    let address = "";
    if (profileData?.address1Text) address += `${profileData?.address1Text}<br aria-hidden="true" />`;
    if (profileData?.address2Text) address += `${profileData?.address2Text}`;
    return address;
  };
  const addressText = returnAddressText();
  const infoObject: any = {
    head: {
      gridtitle: profileData?.employeeNameText,
      texttoread: editIcon.textToRead,
      showediticon: showInfoEditable,
      editbtndata: {
        className: "link icon-action edit-icon float-right",
        ariaLabel: editIcon.textToRead + " " + profileData?.employeeNameText,
        onSubmit: reverseInfoMode
      }
    },
    body: {
      gridRowData: [
        isNameEditable &&
          infoMode === "edit" && [
            {
              type: "text",
              name: "firstName",
              mode: !isNameEditable ? "edit" : "view",
              display: lblEditFirstName.display,
              texttoread: lblProfileFirstNamePlaceHolder.display,
              value: sessioncurrentUser?.firstName,
              maxLength: 50,
              handlechange: onInputChange,
              errormessage: infoErrorMessage?.firstNameError,
              colclassname: "col-sm-12 col-md-4",
              lblclassname: "col-sm-12 mb-2"
            },
            {
              type: "text",
              name: "lastName",
              mode: isNameEditable ? "edit" : "view",
              display: lblEditLastName.display,
              texttoread: lblProfileLastNamePlaceHolder.display,
              value: sessioncurrentUser?.lastName,
              maxLength: 50,
              handlechange: onInputChange,
              errormessage: infoErrorMessage?.lastNameError,
              colclassname: "col-md-4 col-sm-12 mt-2 mt-md-0",
              lblclassname: "col-sm-12 mb-2"
            },
            {
              type: "text",
              name: "initial",
              mode: isNameEditable ? "edit" : "view",
              display: lblEditInitial.display,
              texttoread: lblProfileInitialPlaceHolder.display,
              value: sessioncurrentUser?.initial,
              maxLength: 1,
              handlechange: onInputChange,
              errormessage: infoErrorMessage?.initialError,
              colclassname: "col-md-4 col-sm-12 mt-2 mt-md-0",
              lblclassname: "col-sm-12 mb-2"
            }
          ],
        [
          {
            type: "text",
            name: "dateOfBirth",
            display: lblBirthDate.display,
            texttoread: lblBirthDate.textToRead,
            mode: "view",
            datetime: sessioncurrentUser?.dateOfBirth?.display,
            colclassname: "col-sm-12 col-md-7",
            fgclass: "peronal-info-details",
            lblclassname: "edit-input-label mt-2"
          },
          {
            type: "select",
            name: "gender",
            classname: "form-control login-input-field",
            display: lblGender.display,
            texttoread: lblGender.textToRead + " " + profileData?.genderText,
            mode: "view",
            value: profileData?.genderText,
            handlechange: onInputChange,
            colclassname: "col-sm-12 col-md-5",
            fgclass: "peronal-info-details",
            lblclassname: "edit-input-label mt-2"
          }
        ],
        [
          profileData?.maritalStatusText && {
            type: "select",
            name: "maritalStatus",
            display: lblMaritalStatus.display,
            texttoread: lblMaritalStatus.textToRead + " " + profileData?.maritalStatusText,
            mode: infoMode,
            selectedvalue: sessioncurrentUser?.maritalStatus,
            value: profileData?.maritalStatusText,
            options: profileData?.maritalStatusList,
            handlechange: onInputChange,
            colclassname: infoMode === "edit" ? "col-sm-12 col-md-7 mb-2" : "col-7",
            fgclass: "d-flex",
            lblclassname: infoMode === "edit" ? "col-auto col-md-4 col-sm-3 form-label me-2 my-2 text-nowrap" : "text-nowrap",
            classname: infoMode === "edit" ? "m-0 ms-md-4" : ""
          },
          {
            type: "select",
            name: "language",
            mode: "view",
            display: lblLanguage.display,
            texttoread: lblLanguage.textToRead + " " + profileData?.languageText,
            selectedvalue: sessioncurrentUser?.languageCode,
            value: profileData?.languageText,
            options: profileData?.languageList,
            colclassname: "col-md-5",
            fgclass: "d-flex",
            lblclassname: infoMode === "edit" ? "mt-sm-2 " : "",
            classname: "col"
          }
        ],
        [
          {
            type: "text",
            name: "salary",
            mode: "view",
            skip: !profileData?.showEarningsInProfile ? "1" : null,
            display: profileData?.salaryLabel,
            texttoread: profileData?.salaryLabel + " " + profileData?.salaryText,
            value: profileData?.salaryText,
            colclassname: "col-auto",
            fgclass: "d-flex flex-wrap",
            lblclassname: "col-auto"
          }
        ],
        (infoMode !== "edit" || !isAddressEditable) && [
          {
            type: "text",
            name: "address",
            mode: "view",
            display: lblEditAddress?.display,
            value: addressText,
            colclassname: "col-sm-12",
            lblbpfixclassname: "text-nowrap",
            fgclass: "d-flex"
          }
        ],
        isAddressEditable &&
          infoMode === "edit" && [
            {
              type: "text",
              name: "address1",
              mode: "edit",
              display: lblProfileAddress1PlaceHolder.display,
              value: address?.address1,
              maxLength: 50,
              handlechange: onInputChangeAddress,
              errormessage: infoErrorMessage?.address1Error,
              colclassname: "col-sm-12 col-md-11 mt-3 mb-2",
              fgclass: "d-md-flex",
              lblclassname: "col-md-3 my-md-2 m-md-0  text-nowrap mb-2 my-sm-2",
              gridrow: "col"
            },
            {
              type: "text",
              name: "address2",
              mode: "edit",
              display: lblProfileAddress2PalceHolder?.display,
              value: address?.address2,
              maxLength: 50,
              handlechange: onInputChangeAddress,
              errormessage: infoErrorMessage?.address2Error,
              colclassname: "col-sm-12 col-md-11 mb-2",
              fgclass: "d-md-flex",
              lblclassname: "col-md-3 my-md-2 m-md-0 text-nowrap mb-2 my-sm-2",
              gridrow: "col"
            },
            {
              type: "text",
              name: "city",
              mode: "edit",
              display: lblProfileCityPlaceHolder?.display,
              value: address?.city,
              maxLength: 50,
              handlechange: onInputChangeAddress,
              errormessage: infoErrorMessage?.cityError,
              colclassname: "col-sm-12 col-md-11 mb-2",
              fgclass: "d-flex",
              lblclassname: "col-3 my-ms-2 text-nowrap my-2",
              gridrow: "col"
            },
            {
              type: "select",
              name: "provinceCode",
              mode: "edit",
              display: lblProfileProvinceCodePlaceHolder.display,
              selectedvalue: address?.provinceCode,
              value: address?.provinceCode,
              options: profileData?.provincesList,
              handlechange: onInputChangeAddress,
              colclassname: "col-sm-12 col-md-7 mb-2",
              fgclass: "d-flex",
              lblclassname: "col-3 col-md-4 form-label me-md-4 my-2",
              classname: "col m-0 ms-xl-2"
            },
            {
              type: "select",
              name: "countryCode",
              mode: "edit",
              display: lblProfileCountryCodePlaceHolder.display,
              selectedvalue: address?.countryCode,
              value: address?.countryCode,
              options: profileData?.countriesList,
              handlechange: onInputChangeAddress,
              colclassname: "col-md-4 mb-2",
              fgclass: "d-flex gap-md-2",
              lblclassname: "col-3 col-md-auto my-2",
              classname: "col m-0"
            },
            {
              type: "text",
              name: "postalCode",
              mode: "edit",
              display: lblProfilePostalCodePlaceHolder.display,
              value: address?.postalCode,
              maxLength: 8,
              handlechange: onInputChangeAddress,
              errormessage: infoErrorMessage?.postalCodeError,
              colclassname: "col-sm-12 col-md-11 mb-2",
              fgclass: "d-flex",
              lblclassname: "col-4 col-sm-3 form-label text-nowrap my-2",
              gridrow: "col col-sm-auto"
            }
          ]
      ]
    },
    footer: {
      showFooter: infoMode == "edit" ? true : false,
      footerData: [
        {
          variant: "outline-secondary btn-sm btn-cancel",
          display: btnCancel?.display,
          onSubmit: () => cancel("info")
        },
        {
          variant: "outline-secondary btn-sm btn-save float-end",
          display: btnSave?.display,
          disabled: !infoErrorMessage?.isValid,
          onSubmit: () => handleSaveClick("info")
        }
      ]
    },
    errorList: infoMode === "edit" ? errorList : null
  };

  const phoneObject = {
    head: {
      gridtitle: pnlPhoneGroupingText?.display,
      texttoread: editIcon.textToRead + " " + pnlPhoneGroupingText.textToRead,
      showediticon: showPhoneEditable,
      editbtndata: {
        className: "link icon-action edit-icon float-right",
        ariaLabel: editIcon.textToRead + " " + pnlPhoneGroupingText?.textToRead,
        onSubmit: reversePhoneMode
      }
    },
    body: {
      gridRowData: [
        (isHomePhoneEditable || profileData?.homePhoneText) &&
          phoneMode == "view" && [
            {
              type: "text",
              name: "homePhone",
              display: lblEditHomePhone?.display,
              value: sessioncurrentUser?.homePhone?.formattedPhoneNumber,
              mode: "view",
              colclassname: "col-12 col-lg-8 col-xl-7",
              lblclassname: `col-12 col-sm-auto me-2 ${!sessioncurrentUser?.homePhone?.formattedPhoneNumber && "mb-2"}`,
              fgclass: "d-flex flex-wrap",
              textclassname: "col-12 col-sm-auto"
            }
          ],
        (showWorkPhone || profileData?.workPhoneText) &&
          phoneMode == "view" && [
            {
              type: "text",
              name: "workPhone",
              display: lblEditWorkPhone?.display,
              value: sessioncurrentUser?.workPhone?.formattedPhoneNumber,
              mode: "view",
              colclassname: "col-auto col-xl-7",
              lblclassname: `col-12 col-sm-auto me-3 ${!sessioncurrentUser?.workPhone?.formattedPhoneNumber && "mb-2"}`,
              fgclass: "d-flex flex-wrap",
              textclassname: "col-12 col-sm-auto"
            },
            sessioncurrentUser?.workPhone?.extension && {
              type: "text",
              name: "extension",
              display: lblExtension?.display,
              value: sessioncurrentUser?.workPhone?.extension,
              mode: "view",
              lblclassname: "",
              colclassname: "col-auto",
              fgclass: "d-flex align-items-center"
            }
          ],
        (isHomePhoneEditable || profileData?.homePhoneText) &&
          phoneMode == "edit" && [
            {
              type: "text",
              name: "homePhone",
              display: lblEditHomePhone?.display,
              texttoread: lblProfileHomePhonePhoneNumberPlaceHolder.textToRead,
              mode: isHomePhoneEditable ? "edit" : "view",
              maxLength: 14,
              errormessage: phoneErrorMessage?.homePhoneNumberError,
              value: sessioncurrentUser?.homePhone?.formattedPhoneNumber,
              aria: {
                "aria-describedby": "homePhone-lblEnterTenDigits"
              },
              child: (
                <span className="visually-hidden" aria-hidden id="homePhone-lblEnterTenDigits">
                  {lblEnterTenDigits?.display}
                </span>
              ),
              handlechange: (val: Record<string, string>) => changePhoneNumber("homePhone", "homePhoneNumberError", val["homePhone"]),
              props: {
                onBlur: (e: any) => {
                  changePhoneNumber("homePhone", "homePhoneNumberError", e.target.value, true);
                }
              },
              colclassname: "col-12 col-xl-7 mb-3",
              fgclass: "row",
              lblbpfixclassname: "col-12 col-sm-6 col-xl-7 mb-2 my-sm-2 text-nowrap",
              lblclassname: "col-sm-4 my-sm-2",
              gridrow: "col-6 col-sm-4 pe-0"
            }
          ],

        (showWorkPhone || profileData?.workPhoneText) &&
          phoneMode == "edit" && [
            {
              type: "text",
              name: "workPhone",
              display: lblEditWorkPhone?.display,
              texttoread: lblProfileHomePhonePhoneNumberPlaceHolder.textToRead,
              mode: isWorkPhoneEditable ? "edit" : "view",
              maxLength: 14,
              errormessage: phoneErrorMessage?.workPhoneNumberError,
              value: sessioncurrentUser?.workPhone?.formattedPhoneNumber,
              aria: {
                "aria-describedby": "workPhone-lblEnterTenDigits"
              },
              child: (
                <span className="visually-hidden" aria-hidden id="workPhone-lblEnterTenDigits">
                  {lblEnterTenDigits?.display}
                </span>
              ),
              handlechange: (val: Record<string, string>) => changePhoneNumber("workPhone", "workPhoneNumberError", val["workPhone"]),
              props: {
                onBlur: (e: any) => {
                  changePhoneNumber("workPhone", "workPhoneNumberError", e.target.value, true);
                }
              },
              colclassname: "col-12 col-xl-7 mb-3",
              fgclass: "row",
              lblbpfixclassname: "col-12 col-sm-6 col-xl-7 mb-2 my-sm-2 text-nowrap",
              lblclassname: "col-sm-4 my-sm-2",
              gridrow: "col-6 col-sm-4 pe-0"
            },
            {
              type: "text",
              name: "extension",
              display: lblExtension?.display,
              mode: isWorkPhoneEditable ? "edit" : "view",
              maxLength: 5,
              errormessage: phoneErrorMessage?.workextensionError,
              value: sessioncurrentUser?.workPhone?.extension,
              validateKey: onlyNumber,
              handlechange: handleExtensionChange,
              colclassname: "col-12 col-xl-4 mb-3",
              fgclass: "row",
              lblclassname: "col-12 col-sm-6 col-xl-auto mb-2 my-sm-2 text-nowrap",
              gridrow: "col",
              props: {
                style: { width: "8rem" }
              }
            }
          ]
      ]
    },
    footer: {
      showFooter: phoneMode == "edit" ? true : false,
      footerData: [
        {
          variant: "outline-secondary btn-sm btn-cancel",
          display: btnCancel?.display,
          onSubmit: () => cancel("phone")
        },
        {
          variant: "outline-secondary btn-sm btn-save float-end",
          display: btnSave?.display,
          disabled: !phoneErrorMessage?.isValid,
          onSubmit: () => handleSaveClick("phone")
        }
      ]
    },
    errorList: phoneMode === "edit" ? errorList : null
  };

  const emailObject = {
    head: {
      gridtitle: pnlEmailGroupingText?.display,
      texttoread: editIcon.textToRead + " " + pnlEmailGroupingText.textToRead,
      showediticon: showEmailEditable,
      editbtndata: {
        className: "link icon-action edit-icon float-right",
        ariaLabel: editIcon.textToRead + " " + pnlEmailGroupingText?.textToRead,
        onSubmit: reverseEmailMode
      }
    },
    body: {
      gridRowData: [
        (emailMode == "view" ? isWorkEmailEditable || profileData?.workEmailText : showEmailEditable) && [
          {
            type: "text",
            name: "lblWorkEmail",
            display: lblWorkEmail.display,
            mode: "view",
            colclassname: emailMode == "view" ? "col-auto" : "col-12",
            rowclassname: emailMode === "edit" ? "align-items-center mb-3 gx-0" : "mb-2 gx-0",
            lblclassname: emailMode == "view" ? "m-0" : "mb-2"
          },
          (isWorkEmailEditable || profileData?.workEmailText) && {
            type: "text",
            name: "workEmail",
            controlid: "lblWorkEmail",
            mode: isWorkEmailEditable && emailMode == "edit" ? "edit" : "view",
            maxLength: 100,
            errormessage: emailErrorMessage?.workemailError,
            display: emailMode == "view" ? sessioncurrentUser?.workEmail : null,
            value: isWorkEmailEditable && emailMode == "edit" ? sessioncurrentUser?.workEmail : null,
            handlechange: onInputChange,
            colclassname: emailMode == "view" ? "col" : "col-sm-12 col-md-8",
            lblclassname: "d-block text-truncate"
          }
        ],
        (emailMode == "view" ? isHomeEmailEditable || profileData?.personalEmailText : showEmailEditable) && [
          {
            type: "text",
            name: "lblPersonalEmail",
            display: lblPersonalEmail.display,
            mode: "view",
            colclassname: emailMode == "view" ? "col-auto" : "col-12",
            rowclassname: emailMode === "edit" ? "align-items-center mb-4 gx-0" : "mb-3 gx-0",
            lblclassname: emailMode == "view" ? "m-0" : "mb-2"
          },

          (isHomeEmailEditable || profileData?.personalEmailText) && {
            type: "text",
            name: "personalEmail",
            controlid: "lblPersonalEmail",
            mode: isHomeEmailEditable && emailMode == "edit" ? "edit" : "view",
            maxLength: 100,
            errormessage: emailErrorMessage?.personalemailError,
            display: emailMode == "view" ? sessioncurrentUser?.personalEmail : null,
            value: isHomeEmailEditable && emailMode == "edit" ? sessioncurrentUser?.personalEmail : null,
            handlechange: onInputChange,
            colclassname: emailMode == "view" ? "col" : "col-sm-12 col-md-8",
            lblclassname: "d-block text-truncate"
          }
        ],
        [
         (showEmailEditable || profileData?.workEmailText || profileData?.personalEmailText )  && {
            type: "text",
            name: "lblPreferredEmail",
            display: lblPreferredEmail.display,
            value: emailMode == "view" ? (sessioncurrentUser?.selectedEmail === "work" ? lblEditWorkEmail.display : lblEditPersonalEmail.display) : null,
            mode: "view",
            colclassname: "col-sm-12",
            lblclassname: "mb-0",
            rowclassname: emailMode === "view" ? "mb-3 pb-2" : null
          }
        ],
        emailMode === "edit" && [
          {
            type: "choicelist",
            colclassname: "col-12 col-md-6",
            mode: emailMode,
            options: {
              type: "radio",
              fgclass: "mt-3",
              name: "selectedWorkEmail",
              handlechange: changePreferredEmailStatus,
              selected: sessioncurrentUser?.selectedEmail,
              optionsinput: [
                {
                  text: lblEditWorkEmail.display,
                  value: "work"
                }
              ]
            }
          },
          {
            type: "choicelist",
            colclassname: "col-sm-12 col-md-6",
            mode: emailMode,
            options: {
              type: "radio",
              fgclass: "mt-md-3 mb-4",
              name: "selectedpersonalEmail",
              handlechange: changePreferredEmailStatus,
              selected: sessioncurrentUser?.selectedEmail,
              optionsinput: [
                {
                  text: lblEditPersonalEmail.display,
                  value: "personal"
                }
              ]
            }
          }
        ]
      ]
    },
    footer: {
      showFooter: emailMode == "edit" ? true : false,
      footerData: [
        {
          variant: "outline-secondary btn-sm btn-cancel",
          display: btnCancel?.display,
          onSubmit: () => cancel("email")
        },
        {
          type: "submit",
          variant: "outline-secondary btn-sm btn-save float-end",
          display: btnSave?.display,
          onSubmit: () => handleSaveClick("email")
        }
      ]
    },
    errorList: emailMode === "edit" ? errorList : null
  };

  const smokerList = [
    {
      text: lblNonSmoker,
      value: 0
    },
    {
      text: lblSmoker,
      value: 1
    }
  ];

  const smokerStatusObject = (!profileData?.readOnly || sessioncurrentUser?.smokerStatus?.toString() === "0" || sessioncurrentUser?.smokerStatus?.toString() === "1") &&
    !sessioncurrentUser?.smokerDeclarationOptional && {
      cardClass: "h-100",
      head: {
        gridtitle: smokerStatusTitle?.display
      },
      body: {
        gridRowData: [
          [
            !profileData?.readOnly
              ? {
                  type: "choicelist",
                  colclassname: "col-sm-12 col-md-12",
                  mode: "edit",
                  options: {
                    type: "radio",
                    legendtext: smokerStatusMessage.display,
                    name: "reviewProfileSmokerStatus",
                    selected: sessioncurrentUser?.smokerStatus?.toString(),
                    width: "50",
                    fgclass: "float-left",
                    handlechange: changeSmokerStatus,
                    optionsinput: smokerList,
                    wrapperclass: "mb-3"
                  }
                }
              : {
                  type: "text",
                  name: "reviewProfileSmokerStatus",
                  mode: "view",
                  display: smokerStatusMessage.display,
                  colclassname: "col-sm-12 col-md-12"
                },

            !profileData?.readOnly &&
              sessioncurrentUser?.smokerStatus === 0 &&
              profileData?.showSmokerDeclaration &&
              smokerDeclarationChanged && {
                colclassname: "mt-4",
                classname: "primary",
                type: "instructions",
                display: profileData?.smokerDeclarationLabel
              }
          ]
        ]
      }
    };

  return (
    <>
      <div className="row">
        <div className="container">
          {profileData ? (
            <>
              {changeNote && <AlertComponent marginleft={false} propmessageType="light" propmessage={changeNote} />}
              {/*start Info,Address GridComponent */}
              {infoObject && (
                <div className="row">
                  <div className="w-100">
                    <CardComponent gridObject={infoObject}></CardComponent>
                  </div>
                </div>
              )}
              {/*end Info,Address GridComponent  */}

              {/*start Phone GridComponent */}
              {phoneObject && (
                <div className="row">
                  <div className="w-100">
                    <CardComponent gridObject={phoneObject}></CardComponent>
                  </div>
                </div>
              )}
              {/*end Phone GridComponent  */}

              {/*start Email GridComponent */}
              {emailObject && (
                <div className="row">
                  <div className="w-100">
                    <CardComponent gridObject={emailObject}></CardComponent>
                  </div>
                </div>
              )}
              {/*end email GridComponent  */}

              {/*start smoker status GridComponent */}
              {smokerStatusObject && (
                <div className="row">
                  <div className="w-100 pb-4">
                    <CardComponent gridObject={smokerStatusObject}></CardComponent>
                  </div>
                </div>
              )}
              {/*end smoker status GridComponent  */}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ReviewPersonalInfo;